import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { addNotification } from 'actions/globalActions';

// components
import Button from 'components/common/Button';
import Label from 'components/common/Label';
import Drawer from 'components/common/Drawer';
import GroupsTableRowForm from '../GroupsTableRowForm';
// services
import api from 'services/api';

const GroupsTableRow = ({ group = {}, groups = [], labels = [], setGroups }) => {
  const currentLogger = useSelector(state => state.global.currentLogger);
  const dispatch = useDispatch();

  // edit drawer
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isDrawerLoading, setIsDrawerLoading] = useState(false);
  const [isDrawerError, setIsDrawerError] = useState(false);

  // delete drawer
  const [isDeleteDrawerOpen, setIsDeleteDrawerOpen] = useState(false);
  const [isDeleteDrawerLoading, setIsDeleteDrawerLoading] = useState(false);
  const [isDeleteDrawerError, setIsDeleteDrawerError] = useState(false);

  const submitForm = (data) => {
    setIsDrawerLoading(true);
    api.updateGroup(currentLogger, group.id, data, res => {
      setIsDrawerLoading(false);
      const { status, group: newGroup } = res.data;
      if (status === 200) {
        setGroups([
          ...groups.slice(0, groups.findIndex(e => e.id === group.id)),
          newGroup,
          ...groups.slice(groups.findIndex(e => e.id === group.id) + 1)
        ]);
        dispatch(addNotification({
          title: 'Group updated successfully',
          type: 'success',
        }));
        setIsDrawerOpen(false);
      } else {
        setIsDrawerError(true);
      }
    });
  };

  const deleteGroup = () => {
    setIsDeleteDrawerLoading(true);
    api.deleteGroup(currentLogger, group.id, res => {
      setIsDeleteDrawerLoading(false);
      if (res.data.status === 200) {
        setGroups([
          ...groups.slice(0, groups.findIndex(e => e.id === group.id)),
          ...groups.slice(groups.findIndex(e => e.id === group.id) + 1)
        ]);
        dispatch(addNotification({
          title: 'Group delete successfully',
          type: 'success',
        }));
      } else {
        setIsDeleteDrawerError(true);
        dispatch(addNotification({
          title: 'Cannot delete group',
          type: 'warning',
        }));
      }
    });
  };

  return (
    <tr>
      <td>
        {group.name}
      </td>
      <td style={{ width: '100px' }}>
        <span className={cx('icon', {
          'has-text-primary': group.isBot,
          'has-text-grey': !group.isBot
        })}
        >
          <i className="fas fa-robot"></i>
        </span>
      </td>
      <td style={{ width: '100px' }}>
        <span className={cx('icon', {
          'has-text-primary': group.isWhitelist,
          'has-text-grey': !group.isWhitelist
        })}
        >
          <i className="fas fa-check-circle"></i>
        </span>
      </td>
      <td style={{ width: '100px' }}>
        <span className={cx('icon', {
          'has-text-primary': group.isBlacklist,
          'has-text-grey': !group.isBlacklist
        })}
        >
          <i className="fas fa-ban"></i>
        </span>
      </td>
      <td style={{width: '100px'}}>
        {group.botLevel}
      </td>
      <td>
        <Label
          color={group.labelColor ? group.labelColor : 'grey'}
        >
          {group.labelName ? group.labelName : 'No Label'}
        </Label>
      </td>

      <td style={{ width: '50px' }}>
        <Button className="is-pulled-right" size="small" noPadding type="text" onClick={() => setIsDrawerOpen(true)}>
          Edit
        </Button>
        <Drawer isError={isDrawerError} isLoading={isDrawerLoading} isOpen={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
          <GroupsTableRowForm
            group={group}
            labels={labels}
            onSubmitForm={submitForm}
          />
        </Drawer>
      </td>
      <td style={{ width: '50px' }}>
        <button className="delete is-pulled-right" onClick={() => setIsDeleteDrawerOpen(true)} />
        <Drawer
          isError={isDeleteDrawerError}
          isLoading={isDeleteDrawerLoading}
          isOpen={isDeleteDrawerOpen}
          onClose={() => setIsDeleteDrawerOpen(false)}
        >
          <p className="has-text-weight-bold is-size-4 mb-2">Are you sure you want to delete this group?</p>
          <p className="has-text-weight-light">This actions is irreversible and all grouped IPs will have a null group.</p>
          <Button className="is-pulled-right" type="text" onClick={deleteGroup}>
            Delete Group
          </Button>
        </Drawer>
      </td>
    </tr>
  );
};

GroupsTableRow.propTypes = {
  group: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    isBot: PropTypes.bool,
    isWhitelist: PropTypes.bool,
    isBlacklist: PropTypes.bool,
    labelId: PropTypes.string,
    labelName: PropTypes.string,
    labelColor: PropTypes.string
  }),
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      isBot: PropTypes.bool,
      isWhitelist: PropTypes.bool,
      isBlacklist: PropTypes.bool,
      label: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        color: PropTypes.string
      }),
    })
  ),
  labels: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      color: PropTypes.string
    })
  ),
  setGroups: PropTypes.func,
};

export default GroupsTableRow;
