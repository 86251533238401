import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import cx from 'classnames';

import { addNotification } from 'actions/globalActions';

import Label from 'components/common/Label';
import Button from 'components/common/Button';
import DropDown from 'components/common/Dropdown';

const NewGroupForm = ({ onSubmitForm, labels = [] }) => {

  const dispatch = useDispatch();

  const [selectedLabel, setSelectedLabel] = useState({});
  const [selectedName, setSelectedName] = useState('');
  const [selectedBotLevel, setSelectedBotLevel] = useState(0);
  const [selectedIsBot, setSelectedIsBot] = useState(false);
  const [selectedIsWhitelist, setSelectedIsWhitelist] = useState(false);
  const [selectedIsBlacklist, setSelectedIsBlacklist] = useState(false);
  const [selectedIPList, setSelectedIPList] = useState([]);
  const [labelFilter, setLabelFilter] = useState('');

  const handleNameChange = (e) => {
    const { value } = e.target;
    setSelectedName(value);
  };

  const handleBotLevelChange = (e) => {
    const { value } = e.target;
    setSelectedBotLevel(value);
  };

  const handleIsBotChange = () => {
    setSelectedIsBot(!selectedIsBot);
  };

  const handleIsWhitelistChange = () => {
    setSelectedIsWhitelist(!selectedIsWhitelist);
  };

  const handleIsBlacklistChange = () => {
    setSelectedIsBlacklist(!selectedIsBlacklist);
  };

  const handleIPListChange = (e) => {
    const { value } = e.target;
    const ipArray = value.split('\n');
    setSelectedIPList(ipArray);
  };

  const validateIPList = (ipList) => {
    if (ipList.length === 0) {
      return null;
    }
    // IPv4 and IPv6
    const IPRegEx = /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/;
    const badIPs = [];
    ipList.forEach((ip, index) => {
      if (!IPRegEx.test(ip)) {
        badIPs.push(index + 1);
      }
    });
    if (badIPs.length !== 0) {
      return {
        type: 'warning',
        title: 'Invalid IP list',
        description: `${badIPs.length} bad IPs, starting from line ${badIPs[0]}`
      };
    }
    return null;
  };

  const validateName = (name) => {
    // only letters, numbers and whitespaces
    const nameRegEx = /^[A-Za-z0-9 ]+$/;
    if (!nameRegEx.test(name)) {
      return {
        type: 'warning',
        title: 'Invalid group name',
        description: 'Use only letters, numbers and spaces'
      };
    }
    return null;
  };

  const validateBotLevel = (botLevel) => {
    const max = 9999;
    const min = -9999;
    if (botLevel >= max || botLevel <= min) {
      return {
        type: 'danger',
        title: 'Invalid Bot Level',
        description: botLevel >= max ? `Bot Level is greater than the limit of ${max}` : `Bot Level is less than the limit of ${min}`,
      };
    }
    return null;
  };

  const submitForm = () => {
    const validationErrors = [
      validateName(selectedName),
      validateIPList(selectedIPList),
      validateBotLevel(selectedBotLevel),
    ].filter(e => e !== null);
    if (validationErrors.length === 0) {
      onSubmitForm({
        name: selectedName,
        labelId: selectedLabel.id,
        isBot: selectedIsBot,
        isWhitelist: selectedIsWhitelist,
        isBlacklist: selectedIsBlacklist,
        ipList: selectedIPList,
        botLevel: parseInt(selectedBotLevel),
      });
      // clear input
      setSelectedName('');
      setSelectedLabel({});
      setSelectedIsBot(false);
      setSelectedIsWhitelist(false);
      setSelectedIsBlacklist(false);
      setSelectedIPList([]);
    } else {
      dispatch(addNotification(validationErrors[0]));
    }
  };

  const getLabelOptions = () =>
    labels
      .filter(({ name }) => name.toLowerCase().startsWith(labelFilter.toLowerCase()))
      .map(({ id, color, name }) => (
        <a
          href="#"
          className="dropdown-item has-text-left"
          key={id}
          onMouseDown={() => setSelectedLabel({ id, color, name })}
        >
          <Label
            color={color}
          >
            {name}
          </Label>
        </a>
      ));


  const handleLabelFilterChange = (e) => {
    const { value } = e.target;
    setLabelFilter(value);
  };

  return (
    <div className="control">
      <div className="field">
        <label className="label">Name</label>
        <input
          className="input"
          name="name"
          type="text"
          value={selectedName}
          onChange={handleNameChange}
        />
        <p className="help">Only letters, numbers and whitespaces allowed</p>
      </div>

      <div className="field">
        <label className="label">Label</label>
        <Label
          deletable={selectedLabel.name ? true : false}
          onDelete={() => setSelectedLabel({})}
          color={selectedLabel.color ? selectedLabel.color : 'grey'}
        >
          {selectedLabel.name ? selectedLabel.name : 'No Label'}
        </Label>
        <DropDown className="is-pulled-right">
          <div className="field px-2">
            <p className="control has-icons-left">
              <input
                className="input is-small"
                type="text"
                placeholder="Search labels"
                value={labelFilter}
                onChange={handleLabelFilterChange}
              />
              <span className="icon is-small is-left">
                <i className="fas fa-search"></i>
              </span>
            </p>
          </div>
          {getLabelOptions()}
        </DropDown>
        <p className="help">Choose an existing label or leave blank</p>
      </div>

      <div>
        <label className="label">Bot Level</label>
        <input
          className="input"
          type="number"
          name="botLevel"
          value={selectedBotLevel}
          onChange={handleBotLevelChange}
        />
      </div>

      <div className="field">
        <label className="label">Flags</label>
        <div className="control is-flex is-justify-content-space-around">
          <span
            className={cx('icon is-large flag-button', {
              'has-text-primary': selectedIsBot,
              'has-text-grey': !selectedIsBot
            })}
            onClick={handleIsBotChange}
          >
            <i className="fas fa-2x fa-robot"></i>
          </span>
          <span
            className={cx('icon is-large flag-button', {
              'has-text-primary': selectedIsWhitelist,
              'has-text-grey': !selectedIsWhitelist
            })}
            onClick={handleIsWhitelistChange}
          >
            <i className="far fa-2x fa-check-circle"></i>
          </span>
          <span
            className={cx('icon is-large flag-button', {
              'has-text-primary': selectedIsBlacklist,
              'has-text-grey': !selectedIsBlacklist
            })}
            onClick={handleIsBlacklistChange}
          >
            <i className="fas fa-2x fa-ban"></i>
          </span>
        </div>
        <p className="help">Tick the flags that will dictate this group&apos;s behaviour</p>
      </div>

      <div className="field">
        <label className="label">Identified IPs</label>
        <textarea
          className="textarea"
          placeholder="Write your IPs here separated by newline"
          onChange={handleIPListChange}
        >
        </textarea>
        <p className="help">Write your IPs separated by newline</p>
      </div>

      <Button className="is-pulled-right" type="text" onClick={submitForm}>
        Save Changes
      </Button>
    </div>
  );

};

NewGroupForm.propTypes = {
  onSubmitForm: PropTypes.func,
  labels: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      color: PropTypes.string
    })
  )
};

export default NewGroupForm;
