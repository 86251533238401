import React from 'react';
import PropTypes from 'prop-types';
import GroupsTableRow from '../GroupsTableRow';

const GroupsTable = ({ groups = [], labels = [], setGroups }) => {

  const drawGroupRows = () => (
    groups.map((group) => (
      <GroupsTableRow
        key={group.id}
        group={group}
        groups={groups}
        labels={labels}
        setGroups={setGroups}
      />
    ))
  );

  return (
    <table className="table is-fullwidth is-narrow">
      <thead>
        <tr>
          <th>Name</th>
          <th>Bot</th>
          <th>Whitelist</th>
          <th>Blacklist</th>
          <th>Bot Level</th>
          <th>Label</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {drawGroupRows()}
      </tbody>
    </table>
  );
};

GroupsTable.propTypes = {
  groups: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    isBot: PropTypes.bool,
    isWhitelist: PropTypes.bool,
    isBlacklist: PropTypes.bool,
    label: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      color: PropTypes.string
    }),
  })),
  labels: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    color: PropTypes.string
  })),
  setGroups: PropTypes.func
};

export default GroupsTable;
