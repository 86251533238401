import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Chart from 'chart.js/auto';
import 'chartjs-adapter-moment';

import { truncateIntegerNumber } from 'services/valueFormatter';
import moment from 'moment';

const LineChart = ({
  id = 'line-chart',
  height = 300,
  width,
  labels = [],
  data = [],
  color = '#b0413e'
}) => {

  useEffect(() => {
    // create gradient
    const ctx = document.getElementById(id);
    const gradient = ctx.getContext('2d').createLinearGradient(0, 0, 0, 450);
    gradient.addColorStop(0, `${color}dd`);
    gradient.addColorStop(1, '#ffffff00');

    // Convert labels to moment objects
    const parsedLabels = labels.map(label => moment(label));

    let unit = 'hour';
    const diff = parsedLabels[labels.length - 1] - parsedLabels[0];
    if (diff >= 48 * 60 * 60 * 1000) {
      unit = 'day';
    } else if (diff < 60 * 60 * 1000) {
      unit = 'minute';
    }

    const lineChart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: parsedLabels,
        datasets: [
          {
            data,
            fill: true,
            fillColor: false,
            borderColor: color,
            backgroundColor: gradient,
            pointRadius: 1,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        tension: 0.2,
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          x: {
            type: 'time',
            time: {
              unit: unit,
              tooltipFormat: 'DD MMM yyyy HH:mm',
              displayFormats: {
                hour: 'DD MMM HH:mm'
              }
            },
            beginAtZero: false,
            grid: {
              display: false,
              // drawBorder: true,
              // drawOnChartArea: true,
            },
          },
          y: {
            ticks: {
              // maxTicksLimit: 8,
              callback: (value) => truncateIntegerNumber(value),
            },
            beginAtZero: true,
            grid: {
              display: true,
              drawBorder: true,
            },
          },
        },
      },
    });

    // when component unmounts
    return () => {
      lineChart.destroy();
    };
  }, [color, data, id, labels]);

  return (
    <div className="chart-container">
      <canvas id={id} height={height} width={width} />
    </div>
  );
};

LineChart.propTypes = {
  id: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.any),
  labels: PropTypes.arrayOf(PropTypes.any),
  height: PropTypes.number,
  width: PropTypes.number,
  color: PropTypes.any,
};

export default LineChart;
