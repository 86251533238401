import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Layout from 'components/Layout/Layout';
import Table from 'components/common/Table';
import api from 'services/api';

const Blacklist = () => {
  const currentLogger = useSelector((state) => state.global.currentLogger);

  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const [curPage, setCurPage] = useState(1);
  const [nRequests] = useState(50);
  const [hasMore, setHasMore] = useState(true);

  const formatTimestamp = (timestamp) => {
    if (!timestamp) {
      return;
    }

    return new Date(timestamp).toLocaleString();
  };

  const parseData = (data) => {
    if (!data.blackListers) {
      return;
    }
    const blackListers = data.blackListers;
    const labels = data.labels;
    const dataParsed = [];
    for (let i = 0; i < blackListers.length; i++) {
      dataParsed.push([
        i,
        {
          ipAddress: blackListers[i].ipAddress,
          loggerId: currentLogger,
          requesterId: blackListers[i].id,
        },
        blackListers[i].avgTimeTaken,
        blackListers[i].totalRequests,
        formatTimestamp(blackListers[i].firstAccess),
        formatTimestamp(blackListers[i].lastAccess),
        {
          index: i,
          name: blackListers[i].labelName,
          color: blackListers[i].labelColor,
          labels,
          requesterId: blackListers[i].requesterId,
        },
      ]);
    }
    return dataParsed;
  };

  /**
   * Fetches for log data.
   */
  const fetchBlacklisters = useCallback((curPage = 1) => {
    setIsError(false);
    api.getBlacklist(currentLogger, nRequests, curPage, (res) => {
      setIsLoading(false);
      if (res.data.status === 200) {
        if (res.data.BlackListers.length < nRequests) {
          setHasMore(false);
        }
        if (curPage !== 1) {
          setData({
            status: res.data.status,
            loggerId: data.loggerId,
            labels: data.labels,
            blackListers: [...data.blackListers, ...res.data.blackListers],
          });
        } else {
          setData(res.data);
        }
      } else {
        setIsError(true);
      }
    });
  }, [currentLogger, nRequests, data]);

  const incrementPage = () => {
    fetchBlacklisters(curPage + 1);
    setCurPage(curPage + 1);
  };

  /**
   * Calls fetchLogs onload.
   */
  useEffect(() => {
    if (isLoading) {
      fetchBlacklisters();
    }
  }, [isLoading, fetchBlacklisters]);

  return (
    <Layout title={'Bots'} loading={isLoading} error={isError}>
      {isError}
      <Table
        headerAttributes={[
          'IP Address',
          'Avg Time per Request (ms)',
          'Total Requests',
          'First Access',
          'Last Access',
          'Label',
        ]}
        data={parseData(data)}
        drawLabelPosition={6}
        drawIpAddressPosition={1}
        callBackUrl={incrementPage}
        hasMore={hasMore}
        setLoading={setIsLoading}
      />
    </Layout>
  );
};

export default Blacklist;
