import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { addNotification } from 'actions/globalActions';

import Label from 'components/common/Label';
import Button from 'components/common/Button';
import DropDown from 'components/common/Dropdown';

const NewGroupForm = ({ onSubmitForm, labels = [] }) => {
  const dispatch = useDispatch();

  const [selectedLabel, setSelectedLabel] = useState({});
  const [selectedName, setSelectedName] = useState('');
  const [selectedBotLevel, setSelectedBotLevel] = useState(0);

  const [dnsNames, setDnsNames] = useState([]);
  const [newDnsName, setNewDnsName] = useState('');

  const [userAgentNames, setUserAgentNames] = useState([]);
  const [newUserAgentName, setNewUserAgentName] = useState('');

  const [labelFilter, setLabelFilter] = useState('');

  const handleNameChange = (e) => {
    const { value } = e.target;
    setSelectedName(value);
  };

  const handleBotLevelChange = (e) => {
    const { value } = e.target;
    setSelectedBotLevel(value);
  };

  const handleNewDnsName = (e) => {
    const { value } = e.target;
    setNewDnsName(value);
  };

  const handleAddDnsName = (e) => {
    e.preventDefault();
    const newName = [...dnsNames, newDnsName.trim()];
    setDnsNames(newName);
    setNewDnsName('');
  };

  const handleRemoveDnsName = (index) => {
    dnsNames.splice(index, 1);
    setDnsNames([...dnsNames]);
  };

  const handleNewUserAgentName = (e) => {
    const { value } = e.target;
    setNewUserAgentName(value);
  };

  const handleAddUserAgentName = (e) => {
    e.preventDefault();
    const newName = [...userAgentNames, newUserAgentName.trim()];
    setUserAgentNames(newName);
    setNewUserAgentName('');
  };

  const handleRemoveUserAgentName = (index) => {
    console.log(index);
    userAgentNames.splice(index, 1);
    setUserAgentNames([...userAgentNames]);
  };

  const validateName = (name) => {
    // only letters, numbers and whitespaces
    const nameRegEx = /^[A-Za-z0-9 ]+$/;
    if (!nameRegEx.test(name)) {
      return {
        type: 'warning',
        title: 'Invalid group name',
        description: 'Use only letters, numbers and spaces',
      };
    }
    return null;
  };

  const validateBotLevel = (botLevel) => {
    const max = 9999;
    const min = -9999;
    if (botLevel >= max || botLevel <= min) {
      return {
        type: 'danger',
        title: 'Invalid Bot Level',
        description:
          botLevel >= max
            ? `Bot Level is greater than the limit of ${max}`
            : `Bot Level is less than the limit of ${min}`,
      };
    }
    return null;
  };

  const submitForm = () => {
    const validationErrors = [
      validateName(selectedName),
      validateBotLevel(selectedBotLevel),
    ].filter((e) => e !== null);
    if (validationErrors.length === 0) {
      onSubmitForm({
        name: selectedName,
        dnsNames: dnsNames,
        uaNames: userAgentNames,
        labelId: selectedLabel.id,
        botLevel: parseInt(selectedBotLevel),
      });
      // clear input
      setSelectedName('');
      setNewDnsName('');
      setNewUserAgentName('');
    } else {
      dispatch(addNotification(validationErrors[0]));
    }
  };

  const getLabelOptions = () =>
    labels
      .filter(({ name }) =>
        name.toLowerCase().startsWith(labelFilter.toLowerCase())
      )
      .map(({ id, color, name }) => (
        <a
          href="#"
          className="dropdown-item has-text-left"
          key={id}
          onMouseDown={() => setSelectedLabel({ id, color, name })}
        >
          <Label color={color}>{name}</Label>
        </a>
      ));

  const handleLabelFilterChange = (e) => {
    const { value } = e.target;
    setLabelFilter(value);
  };

  const aa = null;
  if (aa !== null) {
    // just to remove some errors remove this later
    onSubmitForm();
    dispatch();
    validateName('la');
  }

  return (
    <div className="control">
      <div className="field">
        <label className="label">Name</label>
        <input
          className="input"
          name="name"
          type="text"
          value={selectedName}
          onChange={handleNameChange}
        />
        <p className="help">Only letters, numbers and whitespaces allowed</p>
      </div>

      <div className="field">
        <label className="label">Label</label>
        <Label
          deletable={selectedLabel.name ? true : false}
          onDelete={() => setSelectedLabel({})}
          color={selectedLabel.color ? selectedLabel.color : 'grey'}
        >
          {selectedLabel.name ? selectedLabel.name : 'No Label'}
        </Label>
        <DropDown className="is-pulled-right">
          <div className="field px-2">
            <p className="control has-icons-left">
              <input
                className="input is-small"
                type="text"
                placeholder="Search labels"
                value={labelFilter}
                onChange={handleLabelFilterChange}
              />
              <span className="icon is-small is-left">
                <i className="fas fa-search"></i>
              </span>
            </p>
          </div>
          {getLabelOptions()}
        </DropDown>
        <p className="help">Choose an existing label or leave blank</p>
      </div>

      <div>
        <label className="label">Bot Level</label>
        <input
          className="input"
          type="number"
          name="botLevel"
          value={selectedBotLevel}
          onChange={handleBotLevelChange}
        />
      </div>

      <div className="field">
        <label className="label">DNS Names</label>
        <form onSubmit={handleAddDnsName}>
          <input
            className="input"
            name="DNS name"
            type="text"
            value={newDnsName}
            onChange={handleNewDnsName}
          />
          <Button>Add Name</Button>
        </form>
        <div className="columns m-1">
          <div className="column">
            <ul>
              {dnsNames.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>
          <div className="column">
            <ul>
              {dnsNames.map((item, index) => (
                <li key={index}>
                  <Button
                    size="small"
                    type="outlined"
                    rounded={true}
                    onClick={() => handleRemoveDnsName(index)}
                  >
                    X
                  </Button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <div className="field">
        <label className="label">User Agent Names</label>
        <form onSubmit={handleAddUserAgentName}>
          <input
            className="input"
            name="DNS name"
            type="text"
            value={newUserAgentName}
            onChange={handleNewUserAgentName}
          />
          <Button>Add Name</Button>
        </form>
        <div className="columns m-1">
          <div className="column">
            <ul>
              {userAgentNames.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>
          <div className="column">
            <ul>
              {userAgentNames.map((item, index) => (
                <li key={index}>
                  <Button
                    size="small"
                    type="outlined"
                    rounded={true}
                    onClick={() => handleRemoveUserAgentName(index)}
                  >
                    X
                  </Button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <Button className="is-pulled-right" type="text" onClick={submitForm}>
        Save Changes
      </Button>
    </div>
  );
};

NewGroupForm.propTypes = {
  onSubmitForm: PropTypes.func,
  labels: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      color: PropTypes.string,
    })
  ),
};

export default NewGroupForm;
