import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Layout from 'components/Layout/Layout';
import Table from 'components/common/Table';
import api from 'services/api';

const Logs = () => {
  const currentLogger = useSelector((state) => state.global.currentLogger);

  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const [curPage, setCurPage] = useState(1);
  const [nRequests] = useState(50);
  const [hasMore, setHasMore] = useState(true);

  const formatTimestamp = (timestamp) => {
    if (!timestamp) {
      return;
    }

    return new Date(timestamp).toLocaleString();
  };

  const formatLocation = (location) => {
    return location ? location : 'Unknown';
  };

  const parseData = (dataToParse) => {
    console.log('data to parse:', dataToParse);
    if (!dataToParse.requests) {
      return;
    }
    const logs = dataToParse.requests;
    const labels = dataToParse.labels;
    const dataParsed = [];
    for (let i = 0; i < logs.length; i++) {
      dataParsed.push([
        i,
        logs[i].method,
        logs[i].uri,
        {
          ipAddress: logs[i].ipAddress,
          loggerId: currentLogger,
          requesterId: logs[i].requesterId,
        },
        formatTimestamp(logs[i].createdAt),
        logs[i].timeTaken,
        formatLocation(logs[i].location),
        {
          index: i,
          name: logs[i].labelName,
          color: logs[i].labelColor,
          labels,
          requesterId: logs[i].requesterId,
        },
        logs[i].groupName,
      ]);
    }
    return dataParsed;
  };

  /**
   * Fetches for log data.
   */
  const fetchLogs = useCallback((curPage = 1) => {
    setIsError(false);
    api.getLogs(currentLogger, nRequests, curPage, (res) => {
      setIsLoading(false);
      if (res.data.status === 200) {
        if (res.data.requests.length < nRequests) {
          setHasMore(false);
        }
        if (curPage !== 1) {
          setData({
            status: res.data.status,
            labels: data.labels,
            requests: [...data.requests, ...res.data.requests],
          });
        } else {
          setData(res.data);
        }
      } else {
        setIsError(true);
      }
    });
  }, [currentLogger, nRequests, data]);

  const incrementPage = () => {
    fetchLogs(curPage + 1);
    setCurPage(curPage + 1);
  };

  /**
   * Calls fetchLogs onload.
   */
  useEffect(() => {
    if (isLoading) {
      fetchLogs();
    }
  }, [isLoading, fetchLogs]);

  return (
    <Layout title={'Logs'} loading={isLoading} error={isError}>
      {isError}
      <Table
        headerAttributes={[
          'Method',
          'URI',
          'IP Address',
          'Timestamp',
          'Processing Time',
          'Location',
          'Label',
          'Group',
          ' ',
        ]}
        drawMethodPosition={1}
        drawURIPosition={2}
        drawIpAddressPosition={3}
        drawLabelPosition={7}
        data={parseData(data)}
        callBackUrl={incrementPage}
        hasMore={hasMore}
        setLoading={setIsLoading}
      />
    </Layout>
  );
};

export default Logs;
